<template>
    <v-container class="px-0 py-4" fluid>
        <v-row
            class="p-0"
            v-for="(category, indexC) of categories"
            :key="indexC"
        >
            <v-col cols="12">
                <v-card class="elevation-0">
                    <v-card-title class="pl-1 text-capitalize">
                        {{ category }}
                    </v-card-title>
                    <v-data-table
                        :headers="PHeaders"
                        class="elevation-0"
                        :items="setting.permissions[category]"
                        :mobile-breakpoint="0"
                        disable-pagination
                        hide-default-footer
                    >
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    props: {
        setting: Object,
    },
    name: 'RolesPermissionsDetails',
    data: () => ({
        PHeaders: [
            {
                text: 'NAME',
                value: 'name',
                width: '20%',
                class: 'grey lighten-3',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                width: '80%',
                class: 'grey lighten-3',
                sortable: false,
            },
        ],
        categories: [],
    }),
    mounted() {
        this.categories = Object.keys(this.setting.permissions)
    },
}
</script>

<style></style>
